import React from "react";
import Navbar from "../components/Navbar";
import Presentation from "../components/Presentation";
import Boxes from "../components/Boxes";
import Footer from "../components/Footer";

class Home extends React.Component {
  render() {
    return (
      <div>
        <Navbar />
        <Presentation />
        <Boxes />
        <Footer />
      </div>
    );
  }
}

export default Home;
