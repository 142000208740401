import React from "react";
import "../sass/subpage.scss";

class Utredning extends React.Component {
  render() {
    return (
      <div class="container">
        <div class="subpage">
          <div class="content">
            <h1>Modererar</h1>
            <p>
              Har ni en knepig fråga som ni vill diskutera, vrida och vända på?
              Har ni ämnen som ni vill penetrera lite djupare? Jag har vid det
              här laget en mycket god vana som moderator, facilitator och
              samtalsledare.
            </p>
            <p>
              Om ni behöver någon som på ett kreativt sätt håller ordning på
              deltagarna, ämnet och tiden och samtidigt driver
              frågeställningarna framåt, låt mig ta hand om uppdraget! Enklare
              tillställningar eller intrikata sammanhang och komplicerade frågor
              spelar ingen roll.
            </p>
          </div>
        </div>
      </div>
    );
  }
}

export default Utredning;
