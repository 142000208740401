import React from "react";
import "../sass/subpage.scss";

class Utredning extends React.Component {
  render() {
    return (
      <div class="container">
        <div class="subpage">
          <div class="content">
            <h1>Analyserar</h1>
            <p>
              Jag tar gärna på mig analytiska uppdrag. De har följt mig genom
              hela mitt yrkesliv. Jag har själv gjort en lång rad analyser, jag
              har läst andras, jag har sammanfattat åt ministrar, rektorer och
              andra beslutsfattare och jag har utformat analysuppdrag som andra
              utfört. Inte minst roller som analyschef inom regeringskansliet
              och som huvudman för en analysmyndighet har givit stor erfarenhet.
              Strategiska överväganden och analyser av kärnverksamheten är några
              intresseområden.
            </p>
          </div>
        </div>
      </div>
    );
  }
}

export default Utredning;
