import React from "react";
import "../sass/presentation.scss";

class Presentation extends React.Component {
  render() {
    return (
      <div class="presentation">
        <div class="container">
          <div class="content">
            <p>Hej!</p>
            <p>
              Efter många år i olika roller inom staten erbjuder jag nu mina
              tjänster privat. Om ni behöver en erfaren analytisk person med
              stor integritet och ett brett register så har ni förhoppningsvis
              kommit rätt. Jag tror mig ha kompetenser som kan hjälpa er att
              utveckla er verksamhet, på kort eller på lång sikt, i vart fall om
              ni behöver någon som analyserar, utvärderar eller modererar. Jag
              tar på mig uppdrag inom bl.a. dessa områden men om intresse finns
              kan vi säkert hitta även andra uppgifter som vi kan göra
              tillsammans, t.ex. styrelseuppdrag, mentorskap eller
              föreläsningar. Hör av dig så kan vi säkert utveckla samarbeten!
              Referenser lämnas givetvis om ni så önskar!
            </p>
          </div>
        </div>
      </div>
    );
  }
}

export default Presentation;
