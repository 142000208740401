import React from "react";
import "../sass/subpage.scss";

class Utredning extends React.Component {
  render() {
    return (
      <div class="container">
        <div class="subpage">
          <div class="content">
            <h1>Utreder</h1>
            <p>
              Hur ska vi göra med den här delen av verksamheten, och vad ska vi
              egentligen säga om den där satsningen som vi gjorde för fyra år
              sedan? Låt mig titta på den! Jag fördjupar mig gärna i den typen
              av uppdrag och genomför utredningar som i sin tur kan fungera som
              beslutsunderlag för er.
            </p>
            <p>
              Om förutsättningarna tillåter så går det kanske att göra en
              utvärdering, men en utredning räcker ofta långt, i vart fall om
              det är ett beslutsunderlag ni vill ha.
            </p>
          </div>
        </div>
      </div>
    );
  }
}

export default Utredning;
